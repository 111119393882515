<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      
      <v-col cols="12" sm="8" md="4">
        <v-col cols="12" class="text-center">
              <!--<div class="loginlogo"><img :src="logo" ></div>-->
            </v-col>
        <h2 class="register-title mb-3 text-center">New Password</h2>
        <v-card class=" pa-5">
        <div class="card card-default">
          <div class="card-body">
            <form autocomplete="off" @submit.prevent="resetPassword" method="post">
              <div class="form-group">
               <label for="email">E-mail</label>
               <v-text-field name="username" :rules="emailRules" type="text" id="username" v-model="email" class="wag-default-input" />
             </div>
             <div class="form-group">
               <label for="email">Password</label>
               <v-text-field name="password" :rules="passwordRule" type="password" id="password" v-model="password" class="wag-default-input" />
             </div>
             <div class="form-group">
               <label for="email">Confirm Password</label>
               <v-text-field name="password_confirmation" :rules="passwordRule" type="password" id="password_confirmation" v-model="password_confirmation" class="wag-default-input" />
             </div>
             <div class="text-center">
                <v-btn type="submit" class="primary mt-4 " x-large>Update</v-btn>
            </div>
           </form>
         </div>
      </div>
   </v-card>
  </v-col>
 </v-row>
 </v-container>
</template>

<script>
import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.use(VueAxios, axios)
axios.defaults.baseURL = process.env.VUE_APP_BASEAPI;

export default {
    data() {
      return {
        token: null,
        email: null,
        password: null,
        password_confirmation: null,
        has_error: false,
         emailRules: [
        (v) => !!v || "Please enter your email address",
        (v) =>
          /.+@.+\..+/.test(v) ||
          "This doesn't appear to be a valid email address",
      ],
      passwordRule: [
        (v) => v.length >= 8 || "Password must be at least 8 characters",
      ],
       logo: require("@/assets/logo.png") 
      }
    },
    methods: {
        resetPassword() {
             Vue.axios.post("/reset-password/"+this.$route.params.token, {
                token: this.$route.params.token,
                email: this.email,
                password: this.password,
                password_confirmation: this.password_confirmation
            })
            .then(() => {

                this.$router.push({name: 'Auth'})
            }, error => {
                console.error(error);
            });
        }
    }
}
</script>
<style scoped>
.row {
  margin-left: 0px;
}
.login-title {
  font-weight: 900;
  font-family: Montserrat !important;
  font-size: 36px;
  color: white;
  text-shadow: 0px 0px 5px #000000;
  text-align: center;
}
</style>