<template>
  <v-container class="fill-height mt-10" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="4">
         <v-row align="center" justify="center">
            <v-col cols="12" sm="9" md="9" class="text-center">
              <!--<div class="loginlogo"><img :src="logo" ></div>-->
            </v-col>
         </v-row>    
        <h2 class="register-title text-center mb-3">Reset Password</h2>
        <v-card class="customrounded  pa-5">
            <span v-if="error"><strong :class=classAdd>{{ errorText }}</strong></span>
            <form autocomplete="off" @submit.prevent="requestResetPassword" method="post" v-if="!hidething">
              <div class="form-group">
                   <v-text-field
              name="username"
              required
              label="Email"
              :rules="emailRules"
              type="text"
              id="username"
              v-model="email"
              
            />
              </div>
             <div class="text-center">
                <v-btn
              type="submit"
              class="primary mt-4 "
              x-large
              >Send Password Link</v-btn
            >
             </div>
            </form>  
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.use(Vuex)
Vue.use(VueAxios, axios)

export default {
    data() {
      return {
        email: null,
        error: false,
        errorText:'',
        hidething:false,
        classAdd:'red--text',
         emailRules: [
        (v) => !!v || "Please enter your email address",
        (v) =>
          /.+@.+\..+/.test(v) ||
          "This doesn't appear to be a valid email address",
      ],
      logo: require("@/assets/logo.png")  
      }
    },
    methods: {
        requestResetPassword() 
        {
           /*this.$store.dispatch("resetPassword",{email: this.email}).then((response) => 
           {
             console.log(response);  
             this.error = true;
             this.errorText = response.data.message;
             if(response.data.message == "Password reset email sent.")
             {
                 this.email = '';
             }
           }).catch((error) => {
              console.log(error);  
          });*/
          axios.defaults.headers.common['Authorization'] = ''; 
          Vue.axios.post('reset-password',{"email":this.email}).then((response) => 
          {
             this.error = true;
             this.classAdd = 'green--text';
             this.errorText = response.data.message;
             
             if(response.data.message == "Password reset email sent.")
             {
                 this.email = '';
                 this.hidething = true;
             }
          }).catch((error) => {
            if (error.response) {
               
             this.error = true;
             this.errorText = error.response.data.message;
            } else if (error.request) {
             console.log(error.request);
            } else {
              console.log('Error', error.message);
            }
          });
        }
    }
}
</script>
<style scoped>
.row {
  margin-left: 0px;
}
.login-title {
  font-weight: 900;
  font-family: Montserrat !important;
  font-size: 36px;
  color: white;
  text-shadow: 0px 0px 5px #000000;
  text-align: center;
}
</style>
