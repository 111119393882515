<template>
    <div >
        <v-row>
            <v-col cols="4" class="removerightpad">
              <v-select v-model="selectedCountry" :items="countries" label="Country" outlined hide-details />
            </v-col>
            <v-col cols="3" class="removerightpad">
              <v-text-field v-model="dialingCode" label="Code" outlined disabled hide-details/>
            </v-col>
            <v-col cols="5">
              <v-text-field v-model="mobile" label="Mobile" outlined hide-details />
            </v-col>
        </v-row>
        <div class="text-center mt-3 mb-2">Or</div>
        <div>
            <v-text-field v-model="email" label="Email" outlined hide-details /> 
        </div>
        <div class="mt-5">
            <v-text-field v-model="otp" label="OTP" outlined  hide-details/> 
        </div>
        <div class="red--text text-center" v-if="error">{{ error }}</div>
        <div class="btn-actions mt-5">
            <div class="first">
                <v-btn type="submit" @click="getotp()" class="small " color="secondary">Get OTP</v-btn>
            </div>
            <div class="second">
                <v-btn type="submit" @click="submitOtp()" :disabled="disablebtn" class="small" color="primary"> Submit </v-btn>
            </div>
        </div>
    </div>
</template>    
<script>
export default {
    data(){
      return {
        selectedCountry: '',
        dialingCode: '',
        mobile: '',
        countries: ['AUS', 'USA', 'UK', 'NZ'],
        countryCodes: { AUS: '+61', USA: '+1', UK: '+44', NZ: '+64' },    
        email:"", 
        otp:"",
        error:"",
        disablebtn:true,
       }
    },
    watch: {
    // Watch for country change and update dialing code accordingly
    selectedCountry(newCountry) {
      this.dialingCode = this.countryCodes[newCountry] || '';
    },
  },
  created() {
    // Add event listener when component is created
    window.addEventListener("beforeunload", this.handleBeforeUnload);
  },
  destroyed() {
    // Remove event listener when component is destroyed
    window.removeEventListener("beforeunload", this.handleBeforeUnload);
  },
   methods:
   {
    handleBeforeUnload(event) {
      const message = "Are you sure you want to leave this page?";
      event.preventDefault();
      event.returnValue = message; // This triggers the default confirmation dialog in most browsers
      localStorage.setItem('from','Auth');
      localStorage.setItem('is_reloaded', true);
      return message; // Some browsers may use this to display the alert message
    },
     getotp()
     {
        this.error = "";
        if(this.mobile !="" && this.email != '')
        {
           this.error = "Please enter only mobile or email";
           return; 
        }
        
        if(this.mobile == "" && this.email == '')
        {
           this.error = "Please enter mobile or email";
           return; 
        }

        if(this.mobile != "" && this.selectedCountry == "")
        {
            this.error = "Please choose country";
            return false;
        }

        if(this.error == "")
        {   
           this.$store.dispatch("getOtp",{email:this.email,mobile:this.mobile,code:this.dialingCode,from:this.$route.name}).then(() =>{
             this.disablebtn = false;
            
           })
        }
     },
     submitOtp(){
      this.disablebtn = true;
      this.$store.dispatch("submitOtp",
         {email:this.email,mobile:this.mobile,code:this.dialingCode,from:this.$route.name,otp:this.otp}).then((response) =>{
             if(response.status)
             {
              console.log(this.$route.name);
                if(this.$route.name == "Auth")
                {
                  //this.$router.push({ name: "home" });
                  const data = "success";
                     this.$emit('update-message', data);
                 
                }  
                else
                {
                   this.$emit("value-sent", "correct");
                }
             } 
             else
             {
              this.error = response.msg;
             
             }
             this.disablebtn = false;
       });
     }
   }
}    
</script>
<style scoped>
.btn-actions{display: flex;}
.btn-actions div.first,.btn-actions div.second{width: 50%;}
.btn-actions div.first{text-align: left;}
.btn-actions div.second{text-align: right;}
.removerightpad{padding-right: 2px !important;}
</style>