import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store';
import Home from '../views/Home.vue'
import Logout from '../components/Logout';
import Auth from '../views/Auth';
import PersonalInformation from '../views/Personalinformation';
import Changepassword from '../views/Changepassword';
import Dashboard from '../views/Dashboard';
import Addquestion from '../views/Addquestion';
import Category from '../views/Category';
import Question from '../views/Question';
import About from '../views/About';
import Faq from '../views/Faq';
import Contact from '../views/Contact';
import Questionasked from '../views/Questionasked';
import Attemptquestion from '../views/Attemptquestion';
import History from '../views/History';
import Election from '../views/Election';
import Electiondashboard from '../Election/Electiondashboard';
import Otp from '../components/Otp.vue';
import ForgotPassword from '../views/ForgotPassword';
import ResetPasswordForm from '../views/ResetPasswordForm';

Vue.use(VueRouter)

const routes = [
  { 
    path: '/reset-password', 
    name: 'reset-password', 
    component: ForgotPassword, 
    meta: { 
      auth:false 
    } 
},
{ 
    path: '/reset-password/:token', 
    name: 'reset-password-form', 
    component: ResetPasswordForm, 
    meta: { 
      auth:false 
    } 
  },
  {
    path: '/otp',
    name: 'otp',
    component: Otp,
    meta: { transition: 'fade-in-left',from: null },
  },
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: { transition: 'fade-in-left',from: null },
  },
  {
    path: '/home',
    name: 'home',
    component: Home,
    meta: { transition: 'fade-in-left',from: null },
  }, 
  {
    path: '/about',
    name: 'about',
    component: About,
    meta: { transition: 'fade-in-left',from: null },
  }, 
  {
    path: '/faq',
    name: 'faq',
    component: Faq,
    meta: { transition: 'fade-in-left',from: null },
  }, 
  {
    path: '/contact',
    name: 'contact',
    component: Contact,
    meta: { transition: 'fade-in-left',from: null },
  }, 
  {
    path: '/auth',
    name: 'Auth',
    component: Auth,
    meta: { transition: 'fade-in-left',from: null },
  },
  {
    path: '/category/:category?',
    name: 'category',
    component: Category,
    meta: {
        title:'Category',
        requiresAuth: false,
        transition: 'fade-in-left',from: null
    }
  },
  {
    path: '/add-question',
    name: 'askquestion',
    component: Addquestion,
    meta: {
        title:'Question',
        requiresAuth: true,
        transition: 'fade-in-left',from: null
    }
  },
  {
    path: '/Question/:id?',
    name: 'question',
    component: Question,
    meta: {
        title:'Question',
        requiresAuth: false,
        transition: 'fade-in-left',from: null
    }
  },
  {
    path: '/attempt-question',
    name: 'questionAttempt',
    component: Attemptquestion,
    meta: {
        title:'Attempted Question',
        requiresAuth: false,
        transition: 'fade-in-left',from: null
    }
  },
  {
    path: '/history',
    name: 'history',
    component: History,
    meta: {
        title:'History',
        requiresAuth: false,
        transition: 'fade-in-left',from: null
    }
  },
  
  {
    path: '/question-asked',
    name: 'questionasked',
    component: Questionasked,
    meta: {
        title:'Question Asked',
        requiresAuth: false,
        transition: 'fade-in-left',from: null
    }
  },
  {
    path: '/change-password',
    name: 'changepassword',
    component: Changepassword,
    meta: {
        title:'Change Password',
        requiresAuth: true,
        transition: 'fade-in-left',from: null
    }
},
{
  path: '/dashboard',
  name: 'dashboard',
  component: Dashboard,
  meta: {
      title:'Dashboard',
      requiresAuth: true,
      transition: 'fade-in-left',from: null
  }
},
{
  path: '/election',
  name: 'election',
  component: Election,
  meta: {
      title:'Election',
      requiresAuth: false,
      transition: 'fade-in-left',from: null
  }
},
{
  path: '/election-dashboard/:url?',
  name: 'electiondashboard',
  component: Electiondashboard,
  meta: {
      title:'Election Dashboard',
      requiresAuth: true,
      transition: 'fade-in-left',from: null
  }
},
 
  {
    path: '/personal-information',
    name: 'personal',
    component: PersonalInformation,
    meta: {
        title:'Personal Information',
        requiresAuth: true,
        transition: 'fade-in-left',from: null
    }
},
  {
    path: '/logout',
    name: 'logout',
    component: Logout,
    meta: { transition: 'fade-in-left',from: null },
}, 


]

const router = new VueRouter({
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  
})

router.beforeEach((to, from, next) => 
{
  if(to.matched.some(record => record.meta.requiresAuth)) 
  {
     let checkval = store.getters.getelectionLogged;  
     if(checkval == 1)
     {
       store.dispatch("destroyToken").then(() => { 
         window.location.reload();
       });
     }
    if(!store.getters.loggedIn) 
    {  
      store.commit('setRouteMeta', to.fullPath);
      next({
        name: 'Auth'
      });
    } else {
      next();
    }
  } else if(to.matched.some(record => record.meta.requiresVisitor)) {
    let checkval = store.getters.getelectionLogged;  
    if(checkval == 1)
    {
       store.dispatch("destroyToken").then(() => { 
        window.location.reload();
      });
    }
    if(store.getters.loggedIn) {
      next({
        name: 'home'
      });
    } else {
      next();
    }
  } else {
    let checkval = store.getters.getelectionLogged;  
    if(checkval == 1)
    {
      store.dispatch("destroyToken").then(() => { 
        window.location.reload();
      });
    }
    next();
  }
  //to.meta.from = to.fullPath;
  
});

export default router
